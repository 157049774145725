// coming_soon.js
import React from 'react';
import './ComingSoon.css';
import Navbar from '../components/Navbar';

const ComingSoon = () => {
    return (
        <div className="ComingSoon">
            <Navbar/>
        <div className="coming-soon-container">
            
            <h1>Coming Soon</h1>
        </div>
        </div>
    );
};

export default ComingSoon;
