import React from 'react';
import './Hero.css';
import background from '../assets/home.png';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
    const navigate = useNavigate();
    return (
        <section className="hero-section" style={{ backgroundImage:  `url(${background})`}}>
            <div className="hero-content">
                <h1>Preserving the <span>Environment</span> with Expert Compliance and Advanced Monitoring Solutions</h1>
                <p>We provide <span>Doorstep</span> pickup Service</p>
                <button className="explore-button" onClick={() => navigate('/services')}>Explore</button>
            </div>
        </section>
    );
};

export default HeroSection;