import React from 'react';
import Navbar from '../components/Navbar';
import './Services.css';
import services1 from '../assets/services1.png';
import services2 from '../assets/services2.png';
import services3 from '../assets/services3.png';

const Services = () => {
    return (
        <div className="consultancy">
            <Navbar />
            <header className="consultancy-header">
                <h1>Services</h1>
                <div className="consultancy-cards">
                <div className="consultancy-card">
                    <img src={services1} alt="Water Quality Testing"/>
                    <h2> WATER QUALITY TESTING</h2>
                            <p>a) Effluent / Sewage treatment plant (ETP/STP)</p>
                            <p>b) DRINKING WATER - Tap/Well/Borewell water</p>
                            <p>c) Swimming pool water</p>
                </div>

                <div className="consultancy-card">
                    <img src={services2} alt="Stack Monitoring"/>
                    <div className="service-info">
                            <h2> STACK (CHIMNEY) MONITORING - DG/Boiler</h2>
                        </div>
                </div>

                <div className="consultancy-card">
                    <img src={services3} alt="Noise Level Survey" />
                    <div className="service-info">
                            <h2> NOISE LEVEL SURVEY</h2>
                        </div>
                </div>
            </div>
           
            </header>

           
        </div>
    );
};

export default Services;
