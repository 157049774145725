// src/About.js
import React from 'react';
import Navbar from '../components/Navbar';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      
        <Navbar />
    
      <section className="about-section">
        <div className="about-content">
          <h1 className="about-title">About us</h1>
          <div className="services">
            <h2>Services:</h2>
            <ul>
              <li>Environmental Monitoring (Stack / Chimney, Water, and Noise) of applicable parameters.</li>
              <li>Consultancy Services towards making an Enterprise "Environmentally Compliant" to all the applicable laws under the Environmental domain.</li>
              <li>To be a Leader in Environmental testing, analysis, and Consultancy in India.</li>
              <li>Manage to get the required statutory clearances - Pollution Board Certificates.</li>
              <li>Research support in the Environmental domain.</li>
            </ul>
          </div>
          <div className="contact-info">
            <p><strong>Registered Office:</strong> FF3, Lotus Garden CHS, Dovondem, Fatorda, Margao Goa</p>
            <p><strong>Laboratory Location:</strong> Shop no SH/5, Texeira Residency, Pedda, Margao Goa</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
