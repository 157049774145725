
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About'; // Import the About page
import Consultancy from './pages/Consultancy'; // Import the Consultancy page
import ComingSoon from './pages/ComingSoon';

function App() {
    return (

        

        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/about" element={<About />} />        
                    <Route path="/consultancy" element={<Consultancy />} /> 
                    <Route path="/coming-soon" element={<ComingSoon/>} />
                </Routes>
        </div>
         </Router>
    );
}

export default App;

