// src/pages/Consultancy.js
import React from 'react';
import Navbar from '../components/Navbar';
import './Consultancy.css';
import consultancy1 from '../assets/consultancy1.png';
import consultancy2 from '../assets/consultancy2.png';
import consultancy3 from '../assets/consultancy3.png';
import { useNavigate } from 'react-router-dom';

const Consultancy = () => {
    const navigate = useNavigate();
    return (
        <div className="consultancy">
            <Navbar />
            <header className="consultancy-header">
                <h1>Consultancy</h1>
                <div className="consultancy-cards">
                <div className="consultancy-card">
                    <img src={consultancy1} alt="Pollution Control License" />
                    <h2>Pollution Control License</h2>
                    <p>Obtaining License from GSPCB (Goa State Pollution Control Board)</p>
                    <ul>
                        <li>Consent-to-Establish (CTE)</li>
                        <li>Consent-to-Operate (CTO)</li>
                        <li>Renewal of CTO</li>
                    </ul>
                </div>

                <div className="consultancy-card">
                    <img src={consultancy2} alt="Consent Compliance" />
                    <h2>Consent Compliance</h2>
                    <p>Preparation and Submission of Consent Compliances for GSPCB</p>
                    <ul>
                        <li>Annual Environmental Statement in Form-V</li>
                        <li>Annual Hazardous Waste Returns in Form-4</li>
                    </ul>
                </div>

                <div className="consultancy-card">
                    <img src={consultancy3} alt="Compliance Report Guide" />
                    <h2>Compliance Report Guide</h2>
                    <p>Preparation and Submission of Six-Monthly Compliance Reports</p>
                    <ul>
                        <li>Environmental Clearance (EC)</li>
                        <li>Coastal Regulation Zone Clearance (CRZ)</li>
                    </ul>
                </div>
            </div>
            <button className="consultancy-button" onClick={() => navigate('/coming-soon')}>Consult Us</button>
            </header>

           
        </div>
    );
};

export default Consultancy;
