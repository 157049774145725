import React from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/Hero';
import './Home.css';

const Home = () => {
    return (
        <div className="home">
            
            <Navbar />
            <HeroSection />
        </div>
    );
};

export default Home;