import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.png';

const Navbar = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Check for small screen size on initial load and resize
    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
        handleResize(); // Set initial screen size
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Full contact details with icons
    const contactDetails = [
        <span>📞 <a href="tel:+919130472176" className="navbar-link">+91 9130472176</a></span>,
        <span>✉️ <a href="mailto:satyen@comptrack.in" className="navbar-link">satyen@comptrack.in</a></span>,
        <span>📍 <a href="https://www.google.com/maps/place/Margao,+Goa" target="_blank" rel="noopener noreferrer" className="navbar-link">Margao, Goa</a></span>
    ];

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const showNextContact = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % contactDetails.length);
    };

    return (
        <header>
            {/* Upper Section */}
            <div className="navbar-upper">
                <div className="navbar-logo">
                    <img src={logo} alt="COMPTRACK" />
                </div>
                <div className="navbar-contact">
                    {/* Show all contact details on larger screens, cycle through them on small screens */}
                    {isSmallScreen ? contactDetails[currentIndex] : contactDetails}
                    {/* '>' button to show next contact detail only on small screens */}
                    {isSmallScreen && (
                        <button onClick={showNextContact} className="next-contact-button">{'>'}</button>
                    )}
                </div>
            </div>

            {/* Lower Section */}
            <nav className="navbar-lower">
                <div className="navbar-menu">
                    <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/consultancy">Consultancy</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                    </ul>
                </div>
               
                

<button className="navbar-button" onClick={() => navigate('/coming-soon')}>
    Book Appointment
</button>

                <button className="navbar-toggle" onClick={toggleMenu}>
                    ☰
                </button>
            </nav>
        </header>
    );
};

export default Navbar;
